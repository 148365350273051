import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import "../../about-us/investor-relations/csr.bootstrap.scss";

import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import CustomLargeCards from "../../../components/card/custom-large-cards";
import QuickLinksMortgage from "../../../components/quick-links/quick-links-mortgage";
import Icon from "../../../components/custom-widgets/icon";
import FaqAccordion from "../../../components/faq/faq-accordion";
import HelpSection from "../../../components/home-loans/help-section";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";
import PreApprovalWorkflow from "../../../components/home-loans/pre-approval-workflow";

const HomeLoans = (props) => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-what-is-a-portfolio-loan-04032023-730.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(relativePath: { eq: "cards/articles-cards/thumbnail-fixer-upper-091923.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/articles-cards/thumbnail-worth-it-to-refinance-06062023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/thumbnail-cross-collateral-12272022.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };

  const heroChevronData = {
    id: "home-loans-hero",
    ...getHeroImgVariables(pageData),
    altText: "Smiling couple sitting on the floor of their new house.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Welcome to WaFd Bank Home Lending",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "home-loans-hero-cta-1",
            class: "btn-white",
            text: "Apply Now",
            type: "button",
            onClick: handleShowLOModal
          }
        },
        {
          id: 3,
          button: {
            containerClass: "mt-3",
            id: "rates-cta",
            class: "btn-light",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Home Loans"
    }
  ];
  const title = "Compare Home Loans",
    description =
      "Learn why people have trusted WaFd Bank for 100 years as their home loan provider. Compare our home and construction loans to find the right loan for you.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-home-loan-02.jpg"
      }
    ]
  };

  const AVG_PRICE = 350000;

  const calculatorData = {
    title: "Home Loan",
    loanType: "Home Loan",
    loanTerm: "30 Year Fixed",
    interestRate: 0,
    estimatedPayment: 1181,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 20,
    downPaymentAmount: AVG_PRICE * 0.2,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.2,
    disclaimersSectionClass: "pt-0"
  };

  const cardsData = useCardBlogsData([
    "/blog/home-ownership/what-is-a-portfolio-loan",
    "/blog/building-remodeling/pros-cons-before-buying-fixer-upper",
    "/blog/home-ownership/when-and-why-you-should-refinance-your-home"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, {
    hasText: false,
    idString: "home-lending-articles-card-0"
  });

  const articleCardsData = {
    sectionClass: "bg-white",
    titleClass: "text-center",
    hasGrowEffect: true,
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-success" }))
  };

  const quickLinksData = [
    {
      id: "ql-first-time-buyer",
      icon: "../../images/icons/first-time-buyer-icon-active.svg",
      hoverIcon: "../../images/icons/first-time-buyer-icon-gray-hover.svg",
      altText: "First Time Buyer",
      text: "First Time Buyer",
      url: "/personal-banking/home-loans/first-time-home-buyer-loans",
      description: "If you're buying your first home, you probably have a lot of questions. We're here to help."
    },
    {
      id: "ql-refinancing",
      icon: "../../images/icons/refinance-icon-active.svg",
      hoverIcon: "../../images/icons/refinance-icon-gray-hover.svg",
      altText: "Refinancing",
      text: "Refinancing",
      url: "/personal-banking/home-loans/mortgage-refinance",
      description:
        "Want to lower your monthly mortgage payment, pay off your mortgage faster or pull cash out for that remodeling project?"
    },
    {
      id: "ql-home-mortgage-loans",
      icon: "../../images/icons/home-mortgage-loans-icon-active.svg",
      hoverIcon: "../../images/icons/mortgage-icon-gray-hover.svg",
      altText: "Home Mortgage Loans",
      text: "Home Mortgage Loans",
      url: "/personal-banking/home-loans/home-mortgage",
      description:
        "WaFd Bank has been a recommended mortgage lender for over a century, helping generations of families to own their own homes."
    },
    {
      id: "ql-smart-start-loan",
      icon: "../../images/icons/smart-start-pictogram-default.svg",
      hoverIcon: "../../images/icons/smart-start-icon-gray-hover.svg",
      altText: "Smart Start Loan",
      text: "Smart Start Loan",
      url: "/personal-banking/home-loans/smart-start-down-payment-assistance",
      description:
        "An affordable home is the gateway to financial success. If you're having trouble qualifying for a conventional mortgage because of your income, a Smart Start is just what you need."
    },
    {
      id: "ql-home-equity-heloc",
      icon: "../../images/icons/home-equity-heloc-icon-active.svg",
      hoverIcon: "../../images/icons/heloc-icon-gray-hover.svg",
      altText: "Home Equity Loans",
      text: "Home Equity Loans",
      url: "/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan",
      description:
        "Use the equity you've built in your home for just about anything you need. We offer a line of credit or a loan secured by your equity, also known as HELOC and HELOAN."
    },
    {
      id: "ql-easy-move-cross-collateral",
      icon: "../../images/icons/cross-collateral-pictogram-default.svg",
      hoverIcon: "../../images/icons/cross-collateral-icon-gray-hover.svg",
      altText: "Easy Move Cross Collateral Home Loan",
      text: "Easy Move Cross Collateral Home Loan",
      url: "/personal-banking/home-loans/easy-move-cross-collateral",
      description:
        "Buy your new home and worry about selling your current house later with an Easy Move Cross Collateral Home Loan."
    },
    {
      id: "ql-lot-loans",
      icon: "../../images/icons/lot-loans-icon-active.svg",
      hoverIcon: "../../images/icons/lot-loans-icon-gray-hover.svg",
      altText: "Lot Loans",
      text: "Lot Loans",
      url: "/personal-banking/home-loans/land-loans",
      description:
        "With a Lot Loan from WaFd Bank, get the financing you need to buy the perfect spot for your new home."
    },
    {
      id: "ql-remodeling",
      icon: "../../images/icons/remodeling-icon-active.svg",
      hoverIcon: "../../images/icons/remodeling-icon-gray-hover.svg",
      altText: "Remodeling",
      text: "Remodeling",
      url: "/personal-banking/home-loans/home-improvement-loans",
      description: "Build on future value! Don't let major housework out-strip your equity."
    },
    {
      id: "ql-construction-loans",
      icon: "../../images/icons/construction-icon-active.svg",
      hoverIcon: "../../images/icons/construction-icon-gray-hover.svg",
      altText: "Construction Loans",
      text: "Construction Loans",
      url: "/personal-banking/home-loans/construction-loans",
      description:
        "WaFd Bank's Construction-to-Permanent loan, also called a custom construction loan, will help you build your dream home that's the right fit for you."
    }
  ];

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "Family moving day with dad holding a cardboard moving box and mom holding baby."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Buy Your New Home Now, Sell Your Current Home Later",
      subtitle:
        "Need the equity in your current house to buy your new home? With our cross-collateral home loan, you won't need to make your offer to buy a new home contingent on selling your existing one.",
      mainButton: {
        url: "/personal-banking/home-loans/easy-move-cross-collateral",
        title: "Find Out How"
      }
    }
  ];

  const faqAccordionData = {
    id: "home-loans-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    faqCategoryNames: ["Home Loans"],
    seeMoreFaqsText: "Mortgage FAQs",
    showContactBar: false
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you're a first time home buyer and have done all your research but want help, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have.",
    hasTexasLink: true
  };

  const preApprovalData = {
    title: "Pre-Approval*",
    text: "A verified Pre-Approval gives you the confidence of knowing how much house you can afford. Our Pre-Approval program is verified by our Underwriting Team, and lets sellers know you're a serious buyer who can qualify for the house you are interested in."
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <LoanOfficerSearch
        isModalSearch={true}
        show={showLOModal}
        handleClose={handleCloseLOModal}
        loanType="Home Loan"
      />

      <div id="csr-nav" className="mt-4 py-3 bg-gray-5">
        <div className="container">
          <div className="row mx-0">
            <div className="px-0 col-12 col-md-6 col-lg-4 py-1 text-left">
              <Link
                id="scroll-to-loan-types"
                to={"#loan-types"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                What Type of Loan is Best for you?
              </Link>
            </div>

            <div className="d-none d-lg-block px-0 col-12 col-md-6 col-lg-4 py-1 text-center">
              <Link
                id="blog-what-do-mortgage-lenders-look-for-link-lg"
                to={"/blog/home-ownership/what-do-mortgage-lenders-look-for"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-right" lib="far" class="mr-2" />
                How Do Banks Approve You for a Mortgage
              </Link>
            </div>
            <div className="d-none d-md-block d-lg-none px-0 col-12 col-md-6 col-lg-4 py-1 text-right">
              <Link
                id="blog-what-do-mortgage-lenders-look-for-link-md"
                to={"/blog/home-ownership/what-do-mortgage-lenders-look-for"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-right" lib="far" class="mr-2" />
                How Do Banks Approve You for a Mortgage
              </Link>
            </div>
            <div className="d-block d-md-none px-0 col-12 col-md-6 col-lg-4 py-1 text-left">
              <Link
                id="blog-what-do-mortgage-lenders-look-for-link-sm"
                to={"/blog/home-ownership/what-do-mortgage-lenders-look-for"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-right" lib="far" class="mr-2" />
                How Do Banks Approve You for a Mortgage
              </Link>
            </div>

            <div className="d-none d-lg-block px-0 col-12 col-lg-4 py-1 text-right">
              <Link
                id="scroll-to-mortgage-calculator-lg"
                to={"#mortgage-calculator-section"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Mortgage Calculator / Rate Finder
              </Link>
            </div>
            <div className="d-block d-lg-none px-0 col-12 col-lg-4 py-1 text-left">
              <Link
                id="scroll-to-mortgage-calculator-sm"
                to={"#mortgage-calculator-section"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Mortgage Calculator / Rate Finder
              </Link>
            </div>
          </div>
        </div>
      </div>

      <section id="loan-types" className="container pb-4">
        <h1>What Type of Home Loan is Right for You?</h1>
        <h3>
          Whether you're a first time buyer or a seasoned investor, WaFd Bank's friendly and knowledgeable loan officers
          will be with you every step.
        </h3>
        <ReturningBorrowerLink loanType="Home Loan" />
      </section>
      <QuickLinksMortgage
        id="home-loans-quick-links"
        className="py-0"
        linksData={quickLinksData}
        rowClass="justify-content-lg-between"
        colClass="col-lg-auto"
      />
      <MortgageCalculator {...calculatorData} />
      <PreApprovalWorkflow {...preApprovalData} />
      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...articleCardsData} />
      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default HomeLoans;
